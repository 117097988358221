<template>
  <v-card v-if="product && product.expected_delivery" class="mb-2" outlined>
    <v-card-text>
      <div class="secondary--text mb-3">Expected Delivery Time</div>

      <v-form ref="form" class="mb-5">
        <v-row dense>
          <v-col>
            <v-text-field
              v-model.number="product.expected_delivery.time"
              :rules="[rules.required]"
              type="number"
              hide-details
              outlined
              dense
            />
          </v-col>
          <v-col>
            <v-select
              v-model="product.expected_delivery.type"
              :items="['day', 'week', 'month', 'year']"
              :rules="[rules.required]"
              hide-details
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>
      </v-form>

      <v-btn 
        @click="validateForm()"
        :loading="updating" 
        class="box-shadow" 
        color="primary" 
        block 
        small
        text 
      >
        Update
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import rules from '@/rules'
import { mapActions } from 'vuex'

export default {
  props: {
    product: Object,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      updating: false,
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'product': {
      handler(product) {
        if (product && !product.expected_delivery) {
          this.$store.commit('types/updateField', {
            field: 'expected_delivery',
            product: this.product,
            value: {time: null, type: null},
          })
        }
      },
      immediate: true
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('types', [
      'updateProductField'
    ]),

    validateForm() {
      if (this.$refs.form.validate()) {
        this.updating = true

        Promise.resolve(
          this.updateProductField({
            product: this.product,
            value: this.product.expected_delivery,
            field: 'expected_delivery',
          })
        )
        .then(() => {
          this.updating = false
        })
      }
    }
  }
}
</script>