<template>
  <v-card v-if="form" class="mb-2" outlined>
    <v-card-text>
      <div class="secondary--text mb-3">Default Assignee</div>

      <div v-if="form.assignee" class="d-flex align-center">
        <v-list-item @click="showDialog()" class="pl-0" dense>
          <v-list-item-avatar :style="{ overflow: 'visible' }" rounded>
            <UserPhoto :size="40" photoSize="thumb" :id="form.assignee" rounded />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ $store.getters['users/employee'](form.assignee).fullName }}</v-list-item-title>
            <v-list-item-subtitle class="caption grey--text">{{ $store.getters['users/employee'](form.assignee).employeeRole }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-btn 
          @click.prevent="
            updateProductField({
              value: null,
              field: 'assignee',
              product: form,
            })
          "
          color="error" 
          small
          icon 
        >
          <v-icon small>mdi-close</v-icon>
        </v-btn>  
      </div>

      <v-btn v-if="!form.assignee" @click="showDialog()" color="primary" class="box-shadow" text block small>
        Select
      </v-btn>
    </v-card-text>

    <!-- EMPLOYEE DIALOG -->
    <employee-dialog
      ref="employeeDialog" 
      @confirmed="confirmed"
      :default="form.assignee"
      :exclude="form.followers || []"
    />
  </v-card>
</template>

<script>
import EmployeeDialog from '../../users/components/EmployeeDialog.vue'
import { mapActions } from 'vuex'

export default {
  props: {
    form: Object
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    EmployeeDialog
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('types', [
      'updateProductField'
    ]),

    showDialog() {
      this.$refs.employeeDialog.dialog = true
    },

    closeDialog() {
      this.$refs.employeeDialog.dialog = false
    },

    confirmed(employee) {
      this.$refs.employeeDialog.loading = true
      
      Promise.resolve(
        this.updateProductField({
          product: this.form,
          value: employee,
          field: 'assignee',
        })
      )
      .then(() => {
        this.closeDialog()
        this.$refs.employeeDialog.loading = false
      })
    }
  }
}
</script>