<template>
  <v-card v-if="form" class="mb-2" outlined>
    <v-card-text>
      <div class="secondary--text mb-3">Request Icon</div>

      <v-form v-if="!form.icon" ref="form">
        <v-file-input
          v-model="file"
          :rules="[rules.required]"
          accept="image/*"
          ref="fileField"
          prepend-icon=""
          outlined
          dense
        ></v-file-input>

        <v-btn 
          :loading="status.uploading"
          @click="startUpload()" 
          class="box-shadow" 
          :disabled="!file"
          color="primary" 
          block 
          small
          text 
        >
          Upload
        </v-btn>
      </v-form>

      <div v-else-if="form.icon"  class="d-flex justify-center">
        <v-hover v-slot="{ hover }">
          <v-card flat>
            <v-img 
              width="150" 
              :src="url" 
              contain 
            />
            <v-overlay :value="hover" absolute>
              <v-btn @click="confirmDelete()" icon small outlined>
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </v-overlay>
          </v-card>
        </v-hover>
      </div>
    </v-card-text>

    <!-- CONFIRM DELETE -->
    <confirm-delete
      :show="deleteDialog"
      :deleting="deleting"
      message="Delete request icon?"
      @confirmed="deleteConfirmed()"
      @cancel="closeDeleteDialog()"
    />
  </v-card>
</template>

<script>
import rules from '@/rules'
import firebase from 'firebase'
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    form: Object
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      url: null,
      file: null,
      deleting: false,
      deleteDialog: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: state => state.file_upload.status
    })
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'form.icon': {
      handler(icon) {
        if (icon) {
          this.getUrl()
        }
      },
      immediate: true
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('file_upload', [
      'uploadFile'
    ]),

    startUpload() {
      if (this.$refs.form.validate()) {
        Promise.resolve(
          this.uploadFile({ file: this.file, folder: 'request_icons' })
        )
        .then((file) => {
          this.$store.dispatch('types/updateProductField', {
            product: this.form,
            field: 'icon',
            value: file.file,
            message: 'Request icon uploaded'
          })

          this.$store.commit('file_upload/updateStatus', { uploading: false })
          this.file = null
        })
      }
    },

    getUrl() {
      firebase.storage()
      .ref(`/request_icons/${this.form.icon}`)
      .getDownloadURL()
      .then((url) => {
        this.url = url
      })
      .catch(error => {
        console.log(error.message)
      })
    },

    confirmDelete() {
      this.deleteDialog = true
    },

    closeDeleteDialog() {
      this.deleteDialog = false
    },

    deleteConfirmed() {
      this.deleting = true

      firebase.storage()
      .ref(`/request_icons/${this.form.icon}`)
      .delete()
      .then(() => {
        this.$store.dispatch('types/updateProductField', {
          value: null,
          field: 'icon',
          product: this.form,
          message: 'Request icon deleted',
        })
        
        this.$store.dispatch('types/updateProductField', {
          value: null,
          silent: true,
          field: 'iconData',
          product: this.form,
        })
        
        this.deleting = false
        this.closeDeleteDialog()
      })
      .catch(error => {
        this.deleting = false
        this.closeDeleteDialog()
        console.log(error.message)
        this.$store.dispatch('showError', error.message)
      })
    }
  }
}
</script>

