<template>
  <v-card v-if="form" class="mb-2" outlined>
    <v-card-text>
      <v-switch
        @change="$store.dispatch('types/updateProductField', {
          value: form.employeeOnly,
          field: 'employeeOnly',
          product: form,
        })" 
        label="Employee exclusive" 
        v-model="form.employeeOnly"
        class="mt-0" 
        hide-details
        dense 
      ></v-switch>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: { form: Object },
}
</script>