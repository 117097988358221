<template>
  <v-card v-if="product" class="mb-2" outlined>
    <v-card-text>
      <div class="secondary--text mb-3">Default Followers</div>

      <div v-if="product.followers && product.followers.length">
        <v-list dense>
          <v-list-item 
            v-for="(follower, index) in product.followers"
            :key="index"
            class="px-0">
            <v-list-item-avatar :style="{ overflow: 'visible' }" rounded>
              <UserPhoto :size="40" photoSize="thumb" :id="follower" rounded />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ $store.getters['users/employee'](follower).fullName }}</v-list-item-title>
              <v-list-item-subtitle class="caption grey--text">{{ $store.getters['users/employee'](follower).employeeRole }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn @click="removeFollower(follower)" color="error" icon small>
                <v-icon small>mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>

      <v-btn @click="showDialog()" color="primary" class="box-shadow" text block small>
        Select
      </v-btn>
    </v-card-text>

    <!-- EMPLOYEE DIALOG -->
    <employee-dialog
      :default="product.followers || []"
      :exclude="[product.assignee]"
      @confirmed="confirmed"
      ref="employeeDialog" 
      multiple
    />
  </v-card>
</template>

<script>
import EmployeeDialog from '../../users/components/EmployeeDialog.vue'

export default {
  props: {
    product: Object
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    EmployeeDialog
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    showDialog() {
      this.$refs.employeeDialog.showDialog()
    },

    closeDialog() {
      this.$refs.employeeDialog.closeDialog()
      this.$refs.employeeDialog.loading = false
    },

    confirmed(employees) {
      this.$refs.employeeDialog.loading = true
      
      Promise.resolve(
       this.$store.dispatch('types/updateProductField', {
         value: employees,
         field: 'followers',
         product: this.product,
         message: 'Default followers updated'
       })
      )
      .then(() => {
        this.closeDialog()
      })
      
    },
    
    removeFollower(id) {
      this.product.followers.splice(this.product.followers.indexOf(id), 1)
      
      this.$store.dispatch('types/updateProductField', {
        field: 'followers',
        product: this.product,
        message: 'Follower removed',
        value: this.product.followers,
      })
    }

  }
}
</script>