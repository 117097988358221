<template>
  <v-dialog
    v-model="dialog"
    :max-width="$dialog.content"
    scrollable
    tile
  >
    <close-btn 
      @click="closeDialog()" 
      overflow
    />

    <v-card>
      <v-card-title>
        <v-text-field
          @input="updateSearch"
          append-icon="mdi-account-search-outline"
          class="custom-shadow"
          hide-details
          clearable
          outlined
          rounded
          dense
        ></v-text-field>
      </v-card-title>
      <v-card-text class="pa-0">
        <preloader v-if="status.getting" />

        <v-card-text v-if="!confEmployees.length">
          <v-alert type="info" border="left" class="mb-0" text>
            No user found.
          </v-alert>
        </v-card-text>

        <v-fade-transition class="v-list v-sheet theme--light v-list--dense" group>
          <v-list-item
            v-for="employee in orderBy(confEmployees, 'fullName', 'asc')"
            :key="employee.id"
            @click="selected(employee.userid)"
          >
            <v-list-item-avatar :style="{ overflow: 'visible' }" rounded>
              <UserPhoto :size="40" photoSize="thumb" :id="employee.userid" rounded />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title :class="{ 'font-weight-bold primary--text' : isSelected(employee.userid) }" >{{ employee.fullName }}</v-list-item-title>
              <v-list-item-subtitle class="caption">{{ employee.employeeRole }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon
               :color="isSelected(employee.userid) ? 'success': 'grey lighten-3'" 
              >mdi-checkbox-marked-circle-outline</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-fade-transition>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="justify-end gap-3">
        <span v-if="multiple && selectedEmployees.length" class="caption mr-3">
          Selected {{ selectedEmployees.length }}
        </span>

        <v-btn
          @click="confirmed()"
          :disabled="!selectedEmployee && !selectedEmployees.length "
          :loading="loading"
          color="primary"
          small
        >Confirm</v-btn>
        
        <v-btn
          @click="closeDialog()"
          :disabled="loading"
          small
          text
        >Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { orderBy, debounce } from 'lodash'
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    exclude: Array,
    multiple: Boolean,
    default: [Array, String],
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      orderBy,
      search: null,
      dialog: false,
      loading: false,
      selectedEmployee: null,
      selectedEmployees: [],
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: state => state.users.status,
      employees: state => state.users.employees,
    }),

    confEmployees: function () {
      return this.employees.filter(employee => {
        let visible = true
        let excluded = false
        
        if (this.search) visible = employee.fullName.toLowerCase().includes(this.search.toLowerCase())
        if (this.exclude && this.exclude.length) excluded = this.exclude.includes(employee.userid)
        
        return visible && !excluded
      })
    }
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'default': {
      handler(value) {
        if (value && value.length && this.multiple) this.selectedEmployees = JSON.parse(JSON.stringify(value))
        else if (value) this.selectedEmployee = value
      },
      immediate: true
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('users', [
      'getEmployees'
    ]),

    updateSearch: debounce(function (value) {
      this.search = value
    }, 500),

    selected(employee) {
      if (!this.multiple) {
        this.selectedEmployee = employee
      }
      else {
        let exists = this.selectedEmployees.find(e => e == employee)
        if (exists) this.selectedEmployees.splice(this.selectedEmployees.indexOf(exists), 1)
        else this.selectedEmployees.push(employee)
      }
    },

    closeDialog() {
      this.dialog = false
      this.loading = false
      this.clearValues()
    },

    showDialog() {
      if (this.default && this.default.length && this.multiple) 
        this.selectedEmployees = JSON.parse(JSON.stringify(this.default))
      else if (this.default) 
        this.selectedEmployee = this.default

      this.dialog = true
    },

    clearValues() {
      this.selectedEmployee = null
      this.selectedEmployees = []
      this.search = null
    },

    isSelected(id) {
      if (!this.multiple) return this.selectedEmployee == id
      else return this.selectedEmployees.includes(id)
    },

    confirmed() {
      this.$emit('confirmed', this.multiple ? this.selectedEmployees : this.selectedEmployee)
      this.clearValues()
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (!this.status.firstLoadEmployees) this.getEmployees()
    this.clearValues()
  }
}
</script>